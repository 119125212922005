import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import AddressForm from "../components/AddressForm";
import PaymentForm from "../components/PaymentForm";
import Review from "../components/Review";
import logo from "../images/Logo.png";
import axios from "axios";
import { useRef, useState } from "react";
import { Snackbar, Alert } from "@mui/material";
import { useLocation, Navigate, useNavigate } from "react-router-dom";
import DirectTo3D from "../components/DirectTo3D";
import CircularProgress from "@mui/material/CircularProgress";

const logoStyle = {
  width: "140px",
  height: "auto",
  cursor: "pointer",
};

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://www.berliner.com.tr/">
        Berliner Tercüme
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const steps = ["Kişisel Bilgiler", "Ödeme", "Sonuç"];

export default function Checkout() {
  // Check if the required state is missing

  React.useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the window
  }, []);

  const [alertOpen, setAlertOpen] = useState(false);

  const [alertMessage, setAlertMessage] = useState("");

  const addressFormRef = useRef();

  const paymentFormRef = useRef();

  const [activeStep, setActiveStep] = React.useState(0);

  const [studentData, setStudentData] = useState({});

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [paymentLink, setPaymentLink] = useState("");

  const location = useLocation();
  const state = location.state;

  if (!location.state || !location.state.examJSON) {
    // Redirect to homepage or any other page if the condition is not met
    return <Navigate to="/" replace />;
  }

  const examId = state.examJSON.examid;
  const selectedModul = state.examJSON.selectedmodul;
  const exchangeRate = state.examJSON.exchangeRate;

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  const handleNext = async () => {
    if (isSubmitting) return;
    if (activeStep === 0) {
      const data = addressFormRef.current.handleFormSubmit();

      setIsSubmitting(true);

      if (data == null) {
        setIsSubmitting(false);
        return;
      }

      const request = {
        isTcVatandasi: data.isTcVatandasi,
        //isTcVatandasi: false,
        tc: data.tc,
        name: data.name,
        surname: data.surname,
        dateofbirth: data.dateofbirth,
        email: data.email,
        phone: data.phone,
        address1: data.address1,
        address2: data.address2,
        city: data.city,
        province: data.province,
        zip: data.zip,
        country: data.country,
        placeofbirth: data.placeofbirth,
        gender: data.gender,
      };

      setStudentData(request);

      //setActiveStep(activeStep + 1);

      //TODO: COMMENT WHEN TESTİNG
      //return; //TODO DELETE

      //console.log("TC Kimlik Devre Dışı");

      try {
        const response = await axios.post(
          "https://us-central1-sinav-app-alm.cloudfunctions.net/api/tckimlik",
          //"http://127.0.0.1:5001/sinav-app-alm/us-central1/api/tckimlik",
          request
        );

        //console.log(
        //  "Tc kimlik doğrulama sonucu: ",
        //  response.data.TCKimlikNoDogrulaResult
        //);

        if (response.data.TCKimlikNoDogrulaResult == true) {
          setActiveStep(activeStep + 1);
        } else {
          setAlertMessage("TC Kimlik Numaranız Doğrulanamamıştır.");
          setAlertOpen(true);
        }
      } catch (error) {
        if (error.response.status === 422) {
          //this means a data validation error
          setAlertMessage("Email adresinizi doğru girdiğinizden emin olunuz.");
          setAlertOpen(true);
        } else {
          setAlertMessage("Bilgilerinizi doğru girdiğinizden emin olunuz.");
          setAlertOpen(true);
        }
        // Handle errors
      } finally {
        setIsSubmitting(false);
      }
    } else if (activeStep === 1) {
      const data = paymentFormRef.current.handleFormSubmit();

      setIsSubmitting(true);

      if (data == null) {
        //console.log("Could not fetch form data from PaymentForm Component");
        //setActiveStep(0);
        setIsSubmitting(false);
        return;
      }

      const request2 = {
        ...studentData,
        ...data,
        examId: examId,
        selectedModul: selectedModul,
        exchangeRate: exchangeRate,
      };

      try {
        const response = await axios.post(
          "https://us-central1-sinav-app-alm.cloudfunctions.net/api/submit-payment",
          request2
        );

        if (response.data.paymentLink) {
          // Open the payment link in a new tab

          window.location.href = response.data.paymentLink;

          // Move to the next step
          setActiveStep(activeStep + 1);
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          // Check if error.response exists and if the validationhatasi flag is true
          const validationError =
            error.response &&
            error.response.data &&
            error.response.data.validationhatasi;
          if (validationError) {
            // Specific logic for handling validation errors
            setAlertMessage(
              "Kredi kartı bilgilerinizi doğru girdiğinizden emin olunuz."
            );
            setAlertOpen(true);
          } else {
            // Handle other types of errors
            setAlertMessage(
              "Kredi kartı bilgilerinizi doğru girdiğinizden emin olunuz."
            );
            setAlertOpen(true);
          }
        } else {
          setAlertMessage(
            "Kredi kartı bilgilerinizi doğru girdiğinizden emin olunuz."
          );
          setAlertOpen(true);
        }
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const getStepContent = () => {
    switch (activeStep) {
      case 0:
        return <AddressForm ref={addressFormRef} />;
      case 1:
        return <PaymentForm ref={paymentFormRef} />;
      case 2:
        return <DirectTo3D paymentLink={paymentLink} />;
      case 3:
        return <Review />;
      default:
        throw new Error("Unknown step");
    }
  };

  if (!state || !state.examJSON) {
    // Redirect to the homepage if the state is missing
    return <Navigate to="/" replace />;
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar
        position="absolute"
        style={{ backgroundColor: "#252525" }} // Set text color to white for contrast
        elevation={0}
        sx={{
          position: "relative",
          borderBottom: (t) => `1px solid ${t.palette.divider}`,
        }}
      >
        <Toolbar>
          <Typography variant="h6" color="inherit" noWrap>
            <a
              href="https://almancasinavmerkezi.com.tr"
              rel="noopener noreferrer"
            >
              <img
                src={logo}
                style={logoStyle}
                alt="Berliner Almanca Sınav Merkezi"
              />
            </a>
          </Typography>
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <Paper
          variant="outlined"
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
        >
          <Typography component="h1" variant="h4" align="center">
            Ödeme
          </Typography>
          <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography variant="h5" gutterBottom>
                {/*TODO: Değiştir*/}
                Ödemeniz Tamamlandı.
              </Typography>
              <Typography variant="subtitle1">
                {/*TODO: Değiştir*/}
                Kayıt numaranız: #2001539. Ödeme onayınız mail olarak
                gönderilmiştir, Teşekkür eder, sınavınızda başarılar dileriz.
              </Typography>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {getStepContent()}
              {activeStep !== 2 && (
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  {activeStep !== 0 && (
                    <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                      Geri
                    </Button>
                  )}

                  <Button
                    style={{
                      backgroundColor: "#ff0000",
                      color: "#FFF",
                    }} // Set text color to white for contrast
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 3, ml: 1 }}
                  >
                    {isSubmitting ? (
                      <CircularProgress size={24} style={{ color: "white" }} />
                    ) : (
                      "Devam"
                    )}
                  </Button>
                </Box>
              )}
            </React.Fragment>
          )}
        </Paper>
        <Copyright />
      </Container>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
      >
        <Alert onClose={handleAlertClose} severity="error">
          {alertMessage}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}
