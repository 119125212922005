import React, { useState } from "react";
import AdminSinavlar from "../components/AdminSinavlar";
import AdminOgrenciler from "../components/AdminOgrenciler";
import AdminAppBar from "../components/AdminAppBar";
import AdminOptions from "../components/AdminOptions";
import AdminPayments from "../components/AdminPayments";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";

const AdminPage = () => {
  const [activeComponent, setActiveComponent] = useState("sinavlar"); // 'sinavlar' or 'ogrenciler'
  const defaultTheme = createTheme();

  const toggleActiveComponent = (component) => {
    setActiveComponent(component);
  };
  return (
    <div>
      <ThemeProvider theme={defaultTheme}>
        <Box sx={{ display: "flex", mt: 8 }}>
          <CssBaseline />
          <AdminAppBar toggleComponent={toggleActiveComponent} />
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "light"
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
          >
            {activeComponent === "sinavlar" && <AdminSinavlar />}
            {activeComponent === "ogrenciler" && <AdminOgrenciler />}
            {activeComponent === "secenekler" && <AdminOptions />}
            {activeComponent === "odemeler" && <AdminPayments />}
          </Box>
        </Box>
      </ThemeProvider>
    </div>
  );
};

export default AdminPage;
