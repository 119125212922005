import { Copyright } from "@mui/icons-material";
import { db, app } from "../firebaseConfig";
import React, { useEffect, useState } from "react";
import { Grid, Container, Paper, Typography } from "@mui/material";
import { collection, getDocs } from "firebase/firestore/lite";
import AdminOptionBox from "./AdminOptionBox";
import AdminSehirBox from "./AdminSehirBox";

function AdminOptions() {
  const [sinavBasliklari, setSinavBasliklari] = useState([]);

  useEffect(() => {
    const fetchSinavBasliklari = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "SinavBasliklari"));

        const basliklari = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setSinavBasliklari(basliklari);
      } catch (error) {
        //console.log(error);
      }
    };

    fetchSinavBasliklari();
  }, []);

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper sx={{ p: 6, display: "flex", flexDirection: "column" }}>
            <Typography variant="h4">Sınav Seçenekleri Ekleme</Typography>
            <Typography variant="body1" mt={4}>
              Bu sayfada, sınav oluştururkenki seçenekler arasına yenilerini
              ekleyebilirsiniz. Örneğin yeni bir sınav lisansı alındığında (ÖSD,
              DSH vb), sınavın ismi sınav türleri bölümüne eklenerek seçenekler
              arasına dahil edilebilir. Aynı şekilde yeni bir seviye
              geldiğinide, örneğin B1 sınavı yapıyorduk ama artık B1.1 de yapmak
              istiyoruz, seviyeler bölümüne B1.1 yazarak sınav eklerken B1.1'i
              de seçme imkanını kendimize sağlamış oluyoruz.
            </Typography>{" "}
            <Typography variant="subtitle2" mt={4}>
              Not: Yazılı, Sözlü ve Yazılı + Sözlü bölümü kaynak kodun
              içerisinde dinamik olarak fiyatları ve beraberinde bir çok şeyi
              etkilediği için düzenlemeye açık değildir.
            </Typography>
            <Typography variant="subtitle2" mt={2}>
              Not: Buradan bir seçenek kaldırıldığında yapılan değişiklik mevcut
              sınavlara etki etmez.
            </Typography>
          </Paper>
        </Grid>
        {sinavBasliklari.map((baslik) => (
          <Grid item xs={12} key={baslik.id}>
            <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
              <Typography variant="h6">{baslik.id}</Typography>
              {/* Conditional rendering based on baslik.id */}
              {baslik.id === "sehirler" ? (
                <AdminSehirBox
                  items={Object.entries(baslik)
                    .filter(([key, _]) => key !== "id") // Exclude the 'id' field
                    .reduce((acc, [key, value]) => {
                      if (Array.isArray(value)) {
                        // Ensure the value is an array
                        acc[key] = value; // Assign the array to the corresponding key
                      } else {
                        // For non-array values, you might decide to skip or handle differently
                        // For example, converting single values into an array:
                        // acc[key] = [value]; // Uncomment if you need to handle non-array values
                      }
                      return acc;
                    }, {})}
                  docId={baslik.id}
                />
              ) : (
                <AdminOptionBox
                  items={Object.entries(baslik)
                    .filter(([key, _]) => key !== "id") // Exclude the 'id' field
                    .map(([key, value]) => ({
                      id: key,
                      name: value,
                    }))}
                  docId={baslik.id}
                />
              )}
            </Paper>
          </Grid>
        ))}
      </Grid>
      <Copyright sx={{ pt: 4 }} />
    </Container>
  );
}

export default AdminOptions;
