import React, { useState } from "react";
import {
  TextField,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Box,
  Button,
  Alert,
  Snackbar,
} from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import { db } from "../firebaseConfig";
import {
  doc,
  updateDoc,
  arrayRemove,
  arrayUnion,
  deleteField,
} from "firebase/firestore/lite";

function AdminOptionBox({ items, docId }) {
  const [alertInfo, setAlertInfo] = React.useState({
    open: false,
    message: "",
    type: "success", // Default to 'success', but can be 'error', 'warning', 'info'
  });

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertInfo({ ...alertInfo, open: false });
  };

  let description = "";

  if (docId == "moduller") {
    description = "Bu bölümü değiştirmeyiniz.";
  }
  if (docId == "sehirler") {
  }
  if (docId == "seviyeler") {
    description = "Yeni seviye";
  }
  if (docId == "sinavturleri") {
    description = "Yeni sınav türü";
  }

  const [inputValue, setInputValue] = useState("");

  const [currentItems, setCurrentItems] = useState(items);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleAdd = async () => {
    if (inputValue.trim() === "") {
      setAlertInfo({
        open: true,
        message: "Hiçbir değişiklik algılanmadı.",
        type: "error",
      });
      return;
    } // Prevent adding empty items

    const docRef = doc(db, "SinavBasliklari", docId);
    const nextIndex = items.length; // Use the length of the items array as the next index

    try {
      // Update the document with the new value at the next index
      await updateDoc(docRef, {
        [nextIndex]: inputValue,
      });

      setAlertInfo({
        open: true,
        message:
          "Yeni değer eklenmiştir. Değişiklikleri görmek için sayfayı yenileyiniz.",
        type: "success",
      });

      setCurrentItems([...items, inputValue]);
      setInputValue(""); // Reset the input field
    } catch (error) {
      setAlertInfo({
        open: true,
        message: error,
        type: "error",
      });
    }
  };

  const handleEdit = (item) => {
    // Handle edit logic here
    //console.log("Edit item:", item);
  };

  const handleDelete = async (itemId) => {
    const docRef = doc(db, "SinavBasliklari", docId);

    try {
      await updateDoc(docRef, {
        [itemId]: deleteField(),
      });

      setAlertInfo({
        open: true,
        message:
          "Seçenek silinmiştir. Değişiklikleri görmek için sayfayı yenileyiniz.",
        type: "success",
      });

      setCurrentItems(currentItems.filter((item) => item.id !== itemId));
    } catch (error) {
      //console.error("Error deleting the item:", error);
      setAlertInfo({
        open: true,
        message: "Error deleting the item.",
        type: "error",
      });
    }
  };

  return (
    <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
      <TextField
        disabled={docId === "moduller" ? true : false}
        fullWidth
        label={description}
        variant="outlined"
        value={inputValue}
        onChange={handleInputChange}
      />
      <Button
        variant="contained"
        disabled={docId === "moduller" ? true : false}
        onClick={handleAdd}
      >
        Ekle
      </Button>
      <List sx={{ width: "100%", bgcolor: "background.paper" }}>
        {items.map((item) => (
          <ListItem
            key={item.id}
            divider={true}
            secondaryAction={
              <>
                {/*<IconButton
                  edge="end"
                  aria-label="edit"
                  onClick={() => handleEdit(item)}
                  disabled={docId === "moduller" ? true : false}
                >
                  <EditIcon />
            </IconButton>*/}
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => handleDelete(item.id)}
                  disabled={docId === "moduller" ? true : false}
                >
                  <DeleteIcon />
                </IconButton>
              </>
            }
          >
            <ListItemText primary={item.name} />
          </ListItem>
        ))}
      </List>
      <Snackbar
        open={alertInfo.open}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
      >
        <Alert onClose={handleCloseAlert} severity={alertInfo.type}>
          {alertInfo.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default AdminOptionBox;
