import React from "react";
import { Box, Tab, Tabs, Typography } from "@mui/material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function SinavBilgileri() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", overflow: "hidden" }}>
      {" "}
      {/* Container to ensure the Tabs component does not overflow */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          sx={{
            maxWidth: "100%",
            ".MuiTabs-scroller": {
              flexGrow: "0",
            },
          }}
        >
          <Tab label="TELC Sınavı neden önemli" {...a11yProps(0)} />
          <Tab
            label="TELC Sınav öncesi yapılması gerekenler"
            {...a11yProps(1)}
          />
          <Tab
            label="TELC Sınav sırasında yapılması gerekenler"
            {...a11yProps(2)}
          />
          <Tab
            label="TELC Sınav sonrasında yapılması gerekenler"
            {...a11yProps(3)}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Box sx={{ maxWidth: 800, margin: "auto", padding: 2 }}>
          <Typography variant="h5" gutterBottom>
            Telc Sınavı Neden Önemli?
          </Typography>
          <Typography sx={{ textAlign: "left" }} paragraph>
            Küreselleşen dünyada yabancı dil artık herkes için çok önemli.
            Günümüzde farklı ülkede eğitim almak ya da yaşamak için yurtdışına
            başvuru yapanların sayısı oldukça fazla. Buna bağlı olarak da her
            ülkenin ve üniversitenin sistemi, kabul koşulları ve uluslararası
            öğrenci kontenjanı farklıdır. Bu kabul kriterlerinde her yıl bazı
            değişiklikler görülebiliyor.
          </Typography>
          <Typography sx={{ textAlign: "left" }} paragraph>
            Üniversiteler başvuru yoğunluğuna veya öğrenci profillerine göre
            başvuru şartlarında değişiklikler yapabilmektedir. Bu sebeple
            öğrenciler başvuru yapacağı döneme bakarak üniversitelerden güncel
            bilgileri almaları gerekmektedir. Telc sınavını diğer akademik
            sınavlardan ayıran bazı özellikler bulunuyor. Bunları sıralamak
            gerekirse;
          </Typography>
          <Typography component="ul" sx={{ textAlign: "left" }} paragraph>
            <li>Dünya çapında 3000’den fazla sınav merkezi olması</li>
            <li>Yaklaşık 10 dilde sınav içeriğine sahip olması</li>
            <li>Yine dünya çapında bilinen AOÖÇ seviyelerine uygun olması</li>
            <li>
              Üniversiteler, şirketler ve devlet kurumlarının kabul ettiği
              uluslararası çapta tanınan bir sınav olması
            </li>
            <li>Türkiye’de YÖK ve ÖSYM kurumlarının kabul etmesi</li>
            <li>YDS Almanca Eşdeğerlik Listesine alınmış olması</li>
            <li>Sınav tarihlerinin esnek olması</li>
            <li>Sınavların hızlı değerlendirilebilir olması</li>
            <li>Ücretsiz indirilebilir deneme sınavlarının olması</li>
            <li>
              Sınav içeriğinin genel kültür ve gerçek hayattan da alınan güncel
              bir sınav olması
            </li>
            <li>Sınav tarihlerinin fazla olması</li>
            <li>
              Sınavda bir alandan başarısız olduğunuzda tüm sınavı tekrarlamanız
              gerekmez
            </li>
            <li>Sınava istediğiniz kadar girebilirsiniz</li>
          </Typography>
        </Box>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Box sx={{ maxWidth: 800, margin: "auto", padding: 2 }}>
          <Typography variant="h5" gutterBottom>
            Telc Sınavı Öncesi Yapılması Gerekenler
          </Typography>
          <Typography component="div" sx={{ textAlign: "left" }} paragraph>
            <Typography component="div">
              <strong>Sınav Tarihi ve Yeri:</strong> Sınav tarihini ve sınavın
              yapılacağı yeri önceden belirleyin ve bu bilgilere dikkat edin.
              Sınav günü sürpriz yer veya tarih değişiklikleriyle başa çıkmak
              istemezsiniz.
            </Typography>
            <Typography component="div">
              <strong>Sınav Formatını Anlayın:</strong> Telc sınavları, farklı
              seviyelerde ve dil becerilerini ölçen çeşitli sınavlar sunar.
              Hangi seviyede bir sınava gireceğinizi ve sınav formatını iyi
              anladığınızdan emin olun.
            </Typography>
            <Typography component="div">
              <strong>Sınav Süresi:</strong> Sınav süresini önceden bilin.
              Sınavın ne kadar süreceği konusunda bir fikriniz olmalı ve her
              bölüm için yeterli zaman ayırmalısınız.
            </Typography>
            <Typography component="div">
              <strong>Gerekli Belgeler:</strong> Sınav günü gereken tüm
              belgeleri hazırlayın. Genellikle kimlik belgesi, sınav kaydı ve
              sınav ücreti makbuzu gibi belgeler gereklidir.
            </Typography>
            <Typography component="div">
              <strong>İhtiyaçlarınızı Hazırlayın:</strong> Sınav esnasında
              kullanmanıza izin verilen ekipmanları ve materyalleri hazırlayın.
              Örneğin, kalem ve silgi.
            </Typography>
            <Typography component="div">
              <strong>Yeterli Dinlenme:</strong> Sınavdan önce iyi bir gece
              uykusu almak ve sınav günü enerjik olmak önemlidir.
            </Typography>
            <Typography component="div">
              <strong>Sınav Kurallarını Okuyun:</strong> Telc sınavlarının
              belirli kuralları vardır. Sınav öncesinde bu kuralları okuyun ve
              sınav sırasında bu kurallara uyun.
            </Typography>
            <Typography component="div">
              <strong>Soruları Dikkatle Okuyun:</strong> Sınavın her bölümünde
              soruları dikkatle okuyun ve anladığınızdan emin olmadan
              cevaplamaktan kaçının.
            </Typography>
            <Typography component="div">
              <strong>Sınav Günü Ruh Hali:</strong> Sınav günü sakin olmaya
              çalışın. Endişe ve stres sınav performansını olumsuz
              etkileyebilir.
            </Typography>
            <Typography component="div">
              <strong>Zamanı İyi Yönetin:</strong> Sınavın süresi boyunca zamanı
              iyi yönetin. Her bölüm için tahsis edilen süreyi aşmamaya çalışın.
            </Typography>
            <Typography component="div">
              <strong>Cevapları Kontrol Edin:</strong> Sınavı bitirmeden önce,
              cevapları kontrol edin ve eksik veya yanlış cevapları düzeltin.
            </Typography>
            <Typography component="div">
              <strong>Sonuçları Bekleme:</strong> Sınav sonuçları belirli bir
              süre sonra açıklanır. Sonuçları beklerken sabırlı olun ve
              sonuçları alır almaz gereken adımları atın.
            </Typography>
            <Typography component="div">
              <strong>İkinci Şansa Hazırlıklı Olun:</strong> Telc sınavlarını
              ikinci bir şansa ihtiyaç duyabilirsiniz. Sınav sonuçları
              beklenenden düşükse, tekrar hazırlık yapmaya ve sınavı yeniden
              almaya hazırlıklı olun.
            </Typography>
          </Typography>
        </Box>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Box sx={{ maxWidth: 800, margin: "auto", padding: 2 }}>
          <Typography variant="h5" gutterBottom>
            Telc Sınavı Sırasında Yapılması Gerekenler
          </Typography>
          <Typography component="div" sx={{ textAlign: "left" }} paragraph>
            <Typography component="div">
              <strong>Sınav Belgeleri ve Kimlik:</strong> Sınav günü sınav
              kaydınızın bir kopyası, kimlik belgeniz (genellikle pasaport veya
              nüfus cüzdanı) ve sınav ücreti makbuzu gibi gerekli belgeleri
              yanınıza alın. Belgelerinizi kontrol ederek eksiksiz olduğundan
              emin olun.
            </Typography>
            <Typography component="div">
              <strong>Zaman Yönetimi:</strong> Sınavın her bölümü için belirli
              bir süre vardır. Zamanı iyi yönetmek için her bölüm için tahsis
              edilen süreyi göz önünde bulundurun. Her soruya ne kadar zaman
              harcayacağınıza karar verin ve süreyi aşmamaya çalışın.
            </Typography>
            <Typography component="div">
              <strong>Sakin ve Odaklı Olun:</strong> Sınav gergin bir deneyim
              olabilir, ancak sakin kalmaya çalışın. Odaklanmak ve soruları
              dikkatlice okumak önemlidir. Endişe ve panik sınav başarısını
              olumsuz etkileyebilir.
            </Typography>
            <Typography component="div">
              <strong>Cevapları Okuma:</strong> Her soruyu dikkatle okuyun ve ne
              istendiğini anlamaya çalışın. Hızlı cevap vermek yerine, soruları
              iyi anladığınızdan emin olun.
            </Typography>
            <Typography component="div">
              <strong>Çalışma Materyali:</strong> Sınav sırasında kullanmanıza
              izin verilen materyalleri kullanın.
            </Typography>
            <Typography component="div">
              <strong>Son Kontrol:</strong> Sınavı bitirmeden önce cevapları
              tekrar kontrol edin. Eksik veya yanlış cevapları düzeltmeye
              çalışın.
            </Typography>
            <Typography component="div">
              <strong>Sessizlik:</strong> Sınav sırasında sessizliği koruyun ve
              diğer sınav katılımcılarına saygılı davranın.
            </Typography>
            <Typography component="div">
              <strong>Sınav Sonrası:</strong> Sınav sonuçları belirli bir süre
              sonra açıklanır. Sonuçları beklerken sabırlı olun ve gereken
              adımları atın.
            </Typography>
            <Typography component="div">
              <strong>Telc Sınavı ve Kopya Girişimi:</strong> Sonuçları Olumsuz
              Etkileyen Tehlikeli Bir Davranış
            </Typography>
            <Typography component="div">
              Sınavlar, bir kişinin dil becerilerini ve bilgisini objektif bir
              şekilde değerlendirmek için tasarlanmış önemli araçlardır. Bu
              nedenle, sınavların güvenirliği ve adil bir şekilde uygulanması
              büyük önem taşır. Telc sınavı da bu ilkeye dayanarak yapılan bir
              dil sınavıdır. Ancak, bazen bazı kişiler sınav sonuçlarını olumsuz
              yönde etkileyecek bir davranış olan kopya çekme girişiminde
              bulunabilirler.
            </Typography>
            <Typography component="div">
              <strong>Kopya Çekmenin Sonuçları:</strong>
            </Typography>
            <Typography component="ul">
              <li>
                Sınavın İptali: Telc sınavında kopya çekmeye teşebbüs eden
                kişiler, sınavın iptal edilmesi riskiyle karşı karşıyadır. Bu
                durum sınav sonuçlarının geçersiz sayılmasına yol açabilir.
              </li>
              <li>
                Daha Ciddi Yaptırımlar: Kopya çekme girişimi, sadece sınav
                sonuçlarının iptal edilmesi ile sınırlı kalmayabilir. Bazı
                durumlarda, daha ciddi yaptırımlar da uygulanabilir, bu da
                kişinin gelecekteki sınavlara katılmasını zorlaştırabilir.
              </li>
              <li>
                Sorumsuzluk İmajı: Kopya çekme girişimi, kişinin eğitim ve
                kariyeri üzerinde olumsuz bir izlenim yaratabilir. İşverenler
                veya eğitim kurumları, böyle bir davranışı sorumsuzluk olarak
                değerlendirebilir.
              </li>
              <li>
                Kişisel Gururun Zarar Görmesi: Kopya çekme girişimi, kişinin
                özsaygısını ve özgüvenini olumsuz etkileyebilir. Geçerli bir
                şekilde sınavı geçmek, kişisel bir başarı hissi yaratırken,
                kopya çekmek bu gururu zedeler.
              </li>
            </Typography>
            <Typography component="div">
              <strong>Neden Kopya Çekilmemelidir:</strong>
            </Typography>
            <Typography component="ul">
              <li>
                Etik Olmayan Davranış: Kopya çekme, etik olmayan bir
                davranıştır. Dil sınavları, kişinin gerçek dil becerilerini
                değerlendirmeyi amaçlar, bu nedenle kopya çekmek, bu amaçla
                çelişir.
              </li>
              <li>
                Öğrenme Fırsatı Kaçırma: Sınav, öğrenme fırsatı sunar. Dil
                becerilerinizi geliştirmenin ve kendinizi geliştirmenin bir
                yoludur. Kopya çekmek, bu fırsatı kaçırmanıza neden olabilir.
              </li>
              <li>
                Uzun Vadeli Zarar: Kopya çekmek, kısa vadeli bir avantaj
                sağlayabilir, ancak uzun vadede kişisel ve mesleki gelişiminizi
                tehlikeye atabilir.
              </li>
            </Typography>
            <Typography component="div">
              Telc sınavı gibi dil sınavlarını ciddiye almak, dil becerilerinizi
              geliştirmek ve gelecekteki fırsatları açmak için önemlidir. Kopya
              çekmek, bu hedefleri tehlikeye atabilir. Bu nedenle, Telc sınavı
              sırasında ve benzeri sınavlarda etik kurallara uymak ve kopya
              girişimlerinden kaçınmak her zaman en iyisi olacaktır.
            </Typography>
          </Typography>
        </Box>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Box sx={{ maxWidth: 800, margin: "auto", padding: 2 }}>
          <Typography variant="h5" gutterBottom>
            Telc Sınavı Sonrasında Yapılması Gerekenler
          </Typography>

          <Typography component="div" sx={{ textAlign: "left" }} paragraph>
            <Typography component="div">
              Telc sınavını başarıyla tamamladığınızda, başvurduğunuz dil
              seviyesini doğrulayan bir sertifika alırsınız. Ancak sınav sonrası
              dikkat edilmesi gereken bazı önemli hususlar vardır. İşte Telc
              sınav sonrasında unutulmaması gereken bazı önemli noktalar:
            </Typography>
            <Typography component="div">
              <strong>Sertifikayı Saklayın:</strong> Telc sınav sonuçlarınızı ve
              sertifikayı iyi bir şekilde saklayın. Bu belge, dil
              yeterliliğinizi kanıtlamanız için önemlidir. İlerleyen zamanlarda
              iş başvuruları veya eğitim fırsatları için gerekebilir.
            </Typography>
            <Typography component="div">
              <strong>Sınav Sonuçlarını İnceleyin:</strong> Sınav sonuçlarınızı
              inceleyin ve hangi becerilerde daha fazla çalışmanız gerektiğini
              anlamaya çalışın. Bu, dil becerilerinizi daha da geliştirmek için
              bir yol haritası oluşturmanıza yardımcı olabilir.
            </Typography>
            <Typography component="div">
              <strong>Olumlu ve Olumsuz Yanları Değerlendirin:</strong> Sınavı
              geçmiş olmanın gururunu yaşayın, ancak eksik veya zayıf
              alanlarınızı da göz ardı etmeyin. Bu alanlarda daha fazla
              çalışarak genel dil yeterliliğinizi artırabilirsiniz.
            </Typography>
            <Typography component="div">
              <strong>Kariyer ve Eğitim Fırsatlarını Araştırın:</strong> Elde
              ettiğiniz Telc sertifikası, iş başvuruları veya eğitim fırsatları
              için önemli olabilir. Hangi fırsatları araştıracağınızı düşünün ve
              sertifika ile nasıl ilerleyebileceğinizi keşfedin.
            </Typography>
            <Typography component="div">
              <strong>Dil Becerilerinizi Güncel Tutun:</strong> Telc sınavını
              geçtikten sonra dil becerilerinizi güncel tutmak önemlidir. Dil
              becerileriniz zamanla körelebilir, bu nedenle düzenli olarak
              pratik yapmaya devam edin.
            </Typography>
            <Typography component="div">
              <strong>İleri Düzey Sınavları Düşünün:</strong> Eğer Telc
              sınavınızı geçtiyseniz ve dil becerilerinizi daha da geliştirmek
              istiyorsanız, daha yüksek seviyede Telc veya benzeri sınavları
              düşünebilirsiniz.
            </Typography>
            <Typography component="div">
              <strong>Başkalarına İlham Verin:</strong> Sınavı başarıyla
              tamamlamışsanız, başkalarına dil öğrenme konusunda ilham kaynağı
              olabilirsiniz. Dil öğrenme deneyiminizi paylaşarak ve başkalarına
              nasıl başarılı olabileceklerini göstererek topluluğunuza katkıda
              bulunabilirsiniz.
            </Typography>
            <Typography component="div">
              Telc sınavını başarıyla geçmek büyük bir başarıdır ve dil
              becerilerinizi kanıtlar. Ancak bu sadece başlangıçtır. Dil
              öğrenmeye devam etmek ve bu becerileri güncel tutmak, uzun vadeli
              başarı için önemlidir
            </Typography>
          </Typography>
        </Box>
      </TabPanel>
    </Box>
  );
}
