import * as React from "react";
import { useState, useRef, useEffect } from "react";
import { alpha } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";

import Typography from "@mui/material/Typography";

import "dayjs/locale/de";

import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { Link, useNavigate } from "react-router-dom";

import ExamTable from "./ExamTable";

export default function Hero() {
  const [module, setModule] = useState(null);

  const [niveau, setNiveau] = useState(null);

  const [sehir, setSehir] = useState(null);

  const [exam, setExam] = useState(null);

  const [tarih, setTarih] = useState(null);

  const cityPickerRef = useRef(null);

  const [alertOpen, setAlertOpen] = useState(false);

  const [alertMessage, setAlertMessage] = useState("");

  const navigate = useNavigate();

  const formRef = useRef(null);

  //<Button
  //  id="ToCheckout"
  //  variant="contained"
  //  color="primary"
  //  sx={{
  //    mt: { xs: 2, sm: 4 },
  //  }}
  //  onClick={handleToCheckout}
  //>
  //  Devam Et
  //</Button>
  //

  const handleNiveauChange = (event, newValue) => {
    const selectedValue = newValue ? newValue.value : null;

    setNiveau(selectedValue);

    if (exam != null) {
      setExam(null);
    }
  };

  const handleExamChange = (event, newValue) => {
    const selectedValue = newValue ? newValue.value : null;

    setExam(selectedValue);

    if (module != null) {
      setModule(null);
    }
  };

  const handleModuleChange = (event, newValue) => {
    const selectedValue = newValue ? newValue.value : null;

    //console.log("New module " + newValue);

    setModule(selectedValue);

    if (sehir != null) {
      setSehir(null);
    }
  };

  const handleCityChange = (event, newValue) => {
    const selectedValue = newValue ? newValue.value : null;

    setSehir(selectedValue);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  const handleToCheckout = (event, value) => {
    if (sehir === null || exam === null || tarih === null) {
      setAlertMessage("Lütfen tüm bilgileri doğru girdiğinizden emin olunuz.");
      setAlertOpen(true);
      return;
    }

    const examJSON = {
      city: sehir,
      exam: exam,
      date: tarih,
    };

    // Display success message
    setAlertMessage("Ödemeye yönlendiriliyorsunuz");
    setAlertOpen(true);

    // Navigate to '/checkout' with examJSON using useNavigation
    navigate("/checkout", { state: { examJSON } });
  };

  function disableWeekends(date) {
    return date.day() === 0 || date.day() === 6;
  }

  const examDates = [];

  function resetSehir() {
    alert("exam changed");
  }

  const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    const offset = 128;
    if (sectionElement) {
      const targetScroll = sectionElement.offsetTop - offset;
      sectionElement.scrollIntoView({ behavior: "smooth" });
      window.scrollTo({
        top: targetScroll,
        behavior: "smooth",
      });
    }
  };

  const handleDateSelect = (newTarih) => {
    setTarih(newTarih);
    // Do something else if needed
  };

  return (
    <Box
      id="hero"
      sx={{
        width: "100%",
        //backgroundImage: "linear-gradient(180deg, #CEE5FD, #FFF)",
        backgroundImage:
          "linear-gradient( rgba(183, 219, 250, 0.4),rgba(255, 255, 255, 1)), url('06.jpg')",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <Container
        sx={{
          width: "100%",
          position: "relative",
          display: "flex",
          flexDirection: "column", //disable maybe flexdirection column
          alignItems: "center",
          pt: { xs: 20, sm: 40 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Stack spacing={2} useFlexGap sx={{ width: { xs: "90%", sm: "70%" } }}>
          <Typography
            component="h1"
            variant="h1"
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignSelf: "center",
              textAlign: "center",
            }}
          >
            Hemen&nbsp;
            <Typography
              component="span"
              variant="h1"
              sx={{
                color: "#ff0000",
              }}
            >
              Kayıt Ol
            </Typography>
          </Typography>
          <Typography variant="body1" textAlign="center" color="text.secondary">
            Uluslarası geçerli Almanca Dil Sınavları için online kayıt merkezi.
            Kontenjanlar tükenmeden, kısa sürede belgene sahip olmak için
            yakınındaki sınavlara gözat.
          </Typography>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            alignSelf="center"
            spacing={1}
            useFlexGap
            sx={{ pt: 0, width: { xs: "100%", sm: "auto" } }}
          >
            {/*<GoogleMaps />*/}
            <Button
              variant="contained"
              style={{ backgroundColor: "#ff0000", color: "#FFF" }} // Set text color to white for contrast
              onClick={() => scrollToSection("sinavform")}
            >
              kayıt oluştur
            </Button>
          </Stack>
        </Stack>
        <Box
          ref={formRef}
          id="sinavform"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center", // Center children vertically
            p: { md: 2, lg: 8 },
            mt: { xs: 12, sm: 40 },
            alignSelf: "center",
            width: { xs: "90%", sm: "80%", md: "80%", lg: 1000 },
            alignItems: "center",
            backgroundSize: "cover",
            backgroundColor: alpha("#ffffff", 0.9),
            borderRadius: "10px",
            outlineColor: alpha("#ff0000", 0.2),
            boxShadow: `0 0 13px 8px ${alpha("#000000", 0.1)}`,
            maxHeight: "65vh", // Set the maximum height to 70% of the viewport height
            overflow: "auto", // Make the Box scrollable if its content exceeds its height
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center", // Center the typography and table vertically
              alignItems: "center",
              flexGrow: 1, // Make this Box take up available space
            }}
          >
            <Typography sx={{ fontSize: "2em" }}>Almanca Sınavlar</Typography>
          </Box>
          <ExamTable />
        </Box>
      </Container>

      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
      >
        <Alert onClose={handleAlertClose} severity="error">
          {alertMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
