import React, { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

export default function DirectTo3D({ paymentLink }) {
  const [linkFailed, setLinkFailed] = useState(false);

  useEffect(() => {
    if (paymentLink) {
      // Attempt to open the link in a new tab
      const newWindow = window.open(paymentLink, "_blank");

      // Check if the popup was blocked
      if (
        !newWindow ||
        newWindow.closed ||
        typeof newWindow.closed === "undefined"
      ) {
        // Popup blocked or failed to open for some reason
        setLinkFailed(true);
      }
    }
  }, [paymentLink]);

  return (
    <React.Fragment>
      {linkFailed ? (
        <React.Fragment>
          <Typography variant="h5">
            3DS Ekranı açılmadıysa buraya tıklayınız.
          </Typography>
          <Button variant="contained" href={paymentLink} target="_blank">
            3DS Ödeme Yap
          </Button>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <CircularProgress />
          <Typography variant="h5">
            3DS ekranına yönlendiriliyorsunuz
          </Typography>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
