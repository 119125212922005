import * as React from "react";
import { Box, Typography, Container, Grid } from "@mui/material";

const Footer = () => {
  return (
    <Box
      id="iletisim"
      component="footer"
      mt={0}
      sx={{
        width: "100%",
        bgcolor: "#252525",
        color: "white",
        pt: 6,
        pb: 6,
        borderTop: 1,
        borderColor: "divider",
      }}
    >
      <Container>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={6}>
            <Typography
              variant="h6"
              component="div"
              sx={{ fontWeight: "bold", mb: 1 }}
            >
              Berliner Şirketler Grubu
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography>İletişim Bilgileri</Typography>
              <Typography>
                📍 Tuna Cad. No: 8 / 22-23 Kızılay, Çankaya/Ankara
              </Typography>
              <Typography>
                📍 İzmir - 1 Cad. No: 19 / 2 Kızılay, Çankaya/Ankara
              </Typography>
              <Typography>⏰ Pazartesi - Cuma / 09.00 - 22.00</Typography>
              <Typography>⏰ Cumartesi - Pazar / 10.00 - 20.00</Typography>
              <Typography>📞 +90 850 304 07 70</Typography>
              <Typography>✉️ info@berliner.com.tr</Typography>
              <Typography>✉️ akademie@berliner.com.tr</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography
              variant="h6"
              component="div"
              sx={{ fontWeight: "bold", mb: 1 }}
            >
              Hizmetlerimiz
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography>Tercüme</Typography>
              <Typography>Denklik</Typography>
              <Typography>Dil Akademisi</Typography>
              <Typography>İstihdam & Proje</Typography>
              <Typography>Yurtdışı Eğitim</Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={2}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "4rem",
            }}
          >
            {/* Replace "LOGO" with your actual logo component or image */}
            <img
              src={"logo.png"}
              alt={"Berliner"}
              style={{
                width: "100%", // Make image fill the container width
                height: "auto", // Adjust height automatically to keep aspect ratio
                maxWidth: "100%", // Ensure image does not exceed its natural size
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
