import React, { useState } from "react";
import {
  TextField,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Box,
  Button,
  Alert,
  Snackbar,
  Typography,
  Grid,
  Container,
  Modal,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { db } from "../firebaseConfig";
import {
  doc,
  updateDoc,
  arrayRemove,
  arrayUnion,
  FieldValue,
} from "firebase/firestore/lite";
import AddIcon from "@mui/icons-material/Add";

function AdminSehirBox({ items, docId }) {
  const [alertInfo, setAlertInfo] = React.useState({
    open: false,
    message: "",
    type: "success", // Default to 'success', but can be 'error', 'warning', 'info'
  });

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertInfo({ ...alertInfo, open: false });
  };

  const [modalContext, setModalContext] = useState("addLocation"); // 'addLocation' or 'addSehir'
  const [sehirName, setSehirName] = useState("");
  const [open, setOpen] = useState(false); // Modal open state
  const [newLocation, setNewLocation] = useState(""); // State for the new location input
  const [activeHeader, setActiveHeader] = useState(""); // To keep track of which header the add is for

  const handleOpen = (header, context = "addLocation") => {
    setOpen(true);
    setActiveHeader(header); // Set active header to know where to add the new location or new Şehir
    setModalContext(context); // Set the modal context based on the action
    setSehirName(""); // Reset Şehir name if adding a new Şehir
  };
  const handleClose = () => {
    setOpen(false);
    setNewLocation(""); // Clear input on close
  };

  const handleAddSehir = async () => {
    if (sehirName === "" || newLocation === "") {
      setAlertInfo({
        open: true,
        message: "Şehir ve Konum boş olamaz.",
        type: "error",
      });
      return;
    }

    const docRef = doc(db, "SinavBasliklari", "sehirler");

    try {
      await updateDoc(docRef, {
        [sehirName]: arrayUnion(newLocation),
      });
      setAlertInfo({
        open: true,
        message: "Yeni şehir ve konum eklendi.",
        type: "success",
      });
      handleClose(); // Close the modal and reset the form
    } catch (error) {
      console.error("Error adding Şehir and Konum:", error);
      setAlertInfo({
        open: true,
        message: "Şehir ve Konum eklenirken bir hata oluştu.",
        type: "error",
      });
    }
  };

  const handleAddClick = async (newLocation) => {
    if (newLocation == "") {
      // Optionally, handle the case where the input is empty or only whitespace
      setAlertInfo({
        open: true,
        message: "Bu alan boş olamaz.",
        type: "error",
      });
      return;
    }

    const docRef = doc(db, "SinavBasliklari", "sehirler"); // Reference to the "sehirler" document

    try {
      await updateDoc(docRef, {
        [activeHeader]: arrayUnion(newLocation), // Dynamically target the activeHeader field to add the new location
      });
      setAlertInfo({
        open: true,
        message: "Yeni konum eklendi",
        type: "success",
      });
      // Optionally, update your local state here to reflect the addition without needing to fetch from Firestore again
      handleClose(); // Close the modal and reset the form
    } catch (error) {
      console.log(error);
      // Handle errors, e.g., by showing an error message
    }
  };

  const modalBody = (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        borderRadius: "8px",
        boxShadow: 24,
        p: 4,
      }}
    >
      {modalContext === "addLocation" ? (
        <>
          <Typography variant="h6" component="h2">
            {"Yeni Konum Ekle: " + activeHeader}
          </Typography>
          <TextField
            fullWidth
            label="Yeni Konum"
            value={newLocation}
            onChange={(e) => setNewLocation(e.target.value)}
            sx={{ mt: 2, mb: 2 }}
          />
          <Button
            variant="contained"
            onClick={() => handleAddClick(newLocation)}
          >
            Add
          </Button>
        </>
      ) : (
        <>
          <Typography variant="h6" component="h2">
            Yeni Şehir ve Konum Ekle
          </Typography>
          <TextField
            fullWidth
            label="Şehir"
            value={sehirName}
            onChange={(e) => setSehirName(e.target.value)}
            sx={{ mt: 2 }}
          />
          <TextField
            fullWidth
            label="Konum"
            value={newLocation}
            onChange={(e) => setNewLocation(e.target.value)}
            sx={{ mt: 2, mb: 2 }}
          />
          <Button variant="contained" onClick={handleAddSehir}>
            Şehir Ekle
          </Button>
        </>
      )}
    </Box>
  );

  const [inputValue, setInputValue] = useState("");

  const [currentItems, setCurrentItems] = useState(items);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleEdit = (item) => {
    // Handle edit logic here
    console.log("Edit item:", item);
  };

  const handleDeleteSehir = async (header) => {
    const docRef = doc(db, "SinavBasliklari", "sehirler"); // Reference to the "sehirler" document

    try {
      await updateDoc(docRef, {
        [header]: FieldValue.delete(), // Delete the specified header field
      });
      console.log(`Field '${header}' deleted successfully.`);
      // Optionally, update your local state here to reflect the deletion
    } catch (error) {
      console.error("Error deleting field:", error);
      // Handle errors, e.g., by showing an error message
    }
  };

  const handleDeleteLocation = async (header, value) => {
    const docRef = doc(db, "SinavBasliklari", "sehirler"); // Reference to the "sehirler" document in the "SinavBasliklari" collection

    if (items[header].length == 1) {
      setAlertInfo({
        open: true,
        message: "Her şehrin en az bir konumu olmalıdır",
        type: "error",
      });
      return;
    }

    try {
      await updateDoc(docRef, {
        [header]: arrayRemove(value), // Dynamically target the field based on header and remove value from its array
      });

      setAlertInfo({
        open: true,
        message: "Konum silindi",
        type: "success",
      });
      // Optionally, trigger a state update or show a success message/alert here
    } catch (error) {
      console.error("Error removing item:", error);
      // Handle errors, e.g., show an error message/alert
    }
  };

  return (
    <>
      <Snackbar
        open={alertInfo.open}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
      >
        <Alert onClose={handleCloseAlert} severity={alertInfo.type}>
          {alertInfo.message}
        </Alert>
      </Snackbar>
      <Container maxWidth="lg">
        <Button
          startIcon={<AddIcon />}
          onClick={() => handleOpen("", "addSehir")}
        >
          Add Şehir
        </Button>
        <Grid container spacing={2}>
          {" "}
          {/* Horizontal layout of boxes */}
          {Object.entries(items).map(([header, values]) => (
            <Grid item key={header} xs={12} sm={6} md={4}>
              {" "}
              {/* Adjust box width based on screen size */}
              <Box sx={{ p: 2 }}>
                {" "}
                {/* Style the box */}
                <Typography variant="h6" sx={{ mb: 2 }}>
                  {header}{" "}
                  <IconButton
                    onClick={() => handleDeleteSehir(header)}
                    aria-label="delete"
                    size="small"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Typography>
                {values.map((value, index) => (
                  <Box
                    key={index}
                    sx={{ display: "flex", alignItems: "center", mb: 1 }}
                  >
                    <Typography sx={{ flexGrow: 1 }}>
                      {value} {/* List each value */}
                    </Typography>
                    <IconButton
                      onClick={() => handleDeleteLocation(header, value)}
                      aria-label="delete"
                      size="small"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                ))}
                <Button
                  startIcon={<AddIcon />}
                  onClick={() => handleOpen(header)}
                >
                  Add Location
                </Button>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {modalBody}
      </Modal>
    </>
  );
}

export default AdminSehirBox;
