import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { Link, useNavigate } from "react-router-dom";
import { Checkbox } from "@mui/material";
import { FormControlLabel } from "@mui/material";

import { KeyboardArrowDown } from "@mui/icons-material";
import { KeyboardArrowUp } from "@mui/icons-material";

import {
  getFirestore,
  collection,
  getDocs,
  query,
  orderBy,
} from "firebase/firestore/lite";
import { db, app } from "../firebaseConfig";


function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const sinavRows = [];

const sinavlarRef = collection(db, "Sinavlar");
const q = query(sinavlarRef, orderBy("tarih", "desc")); // Replace "date" with your field name
const querySnapshot = await getDocs(q);

function getDataFromFirestore() {
  querySnapshot.forEach((doc) => {
    const sinavObj = doc.data();

    const rowObj = createData(
      doc.id,
      sinavObj.fiyat,
      sinavObj.kayitbaslama,
      sinavObj.kontenjan - sinavObj.kayitliogrenciler.length,
      sinavObj.modul,
      sinavObj.niveau,
      sinavObj.sehir,
      sinavObj.sinavyeri,
      sinavObj.tarih,
      sinavObj.tur,
      sinavObj.kayitbitis,
      sinavObj.yaziliucret,
      sinavObj.sozluucret
    );

    //console.log(doc.id);

    sinavRows.push(rowObj);
  });
}

getDataFromFirestore();

function createData(
  id,
  fiyat,
  kayitbaslama,
  kontenjan,
  modul,
  niveau,
  sehir,
  sinavyeri,
  tarih,
  tur,
  kayitbitis,
  yaziliucret,
  sozluucret
) {
  return {
    id,
    fiyat,
    kayitbaslama,
    kontenjan,
    modul,
    niveau,
    sehir,
    sinavyeri,
    tarih,
    tur,
    kayitbitis,
    yaziliucret,
    sozluucret,
  };
}

const formatNumber = (number, exchangeRate = 1) => {
  const adjustedNumber = number * exchangeRate;
  return new Intl.NumberFormat("tr-TR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(adjustedNumber);
};

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  const [isYaziliChecked, setYaziliChecked] = React.useState(false);
  const [isSozluChecked, setSozluChecked] = React.useState(false);

  React.useEffect(() => {
    // Automatically check and disable checkboxes based on the modul value
    if (row.modul === "Yazılı") {
      setYaziliChecked(true);
      setSozluChecked(false);
    } else if (row.modul === "Sözlü") {
      setYaziliChecked(false);
      setSozluChecked(true);
    } else if (row.modul === "Yazılı/Sözlü") {
      setYaziliChecked(true);
      setSozluChecked(true);
    }
  }, [row.modul]);

  const handleChange = (event) => {
    if (event.target.name === "yazili") {
      setYaziliChecked(!isYaziliChecked);
    } else if (event.target.name === "sozlu") {
      setSozluChecked(!isSozluChecked);
    }
  };

  const calculateFiyat = () => {
    if (row.modul === "Yazılı/Sözlü" && row.yaziliucret && row.sozluucret) {
      if (isYaziliChecked && isSozluChecked) return row.fiyat;
      if (isYaziliChecked) return row.yaziliucret;
      if (isSozluChecked) return row.sozluucret;
    }
    return row.fiyat;
  };

  const exchangeRate = props.exchangeRate;

  const navigate = useNavigate();

  const handleToCheckout = (event, value) => {
    // Display success message
    //setAlertMessage("Ödemeye yönlendiriliyorsunuz");
    //setAlertOpen(true);

    if (exchangeRate == null || exchangeRate == 0) {
      return;
    }

    let selectedModul = "";

    if (isYaziliChecked) {
      selectedModul = "Yazılı";
    } else if (isSozluChecked) {
      selectedModul = "Sözlü";
    }

    if (isYaziliChecked && isSozluChecked) {
      selectedModul = "Yazılı + Sözlü";
    }

    const examJSON = {
      examid: row.id,
      selectedmodul: selectedModul,
      exchangeRate: exchangeRate,
    };

    // Navigate to '/checkout' with examJSON using useNavigation
    navigate("/checkout", { state: { examJSON } });
  };

  const today = new Date();

  // Check if the row.kayitbaslama date is after today
  const isAfterToday = row.kayitbaslama.toDate() < today;

  const isInThePast = row.kayitbitis.toDate() < new Date();

  return (
    <React.Fragment>
      <TableRow
        hover
        onClick={() => setOpen(!open)}
        sx={{ "& > *": { borderBottom: "unset" } }}
      >
        <TableCell component="th" scope="row">
          {row.tarih.toDate().toLocaleDateString("tr-TR", {
            //weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "2-digit", // Add hour in 2-digit format
            minute: "2-digit",
          })}
        </TableCell>
        <TableCell>{row.niveau}</TableCell>
        <TableCell>{row.tur}</TableCell>
        <TableCell>{row.modul}</TableCell>
        <TableCell>{capitalizeFirstLetter(row.sehir)}</TableCell>
        <TableCell
          align="right"
          style={{
            color:
              row.kayitbitis.toDate() < new Date()
                ? "orange"
                : isAfterToday
                ? row.kontenjan <= 5
                  ? "red"
                  : "green"
                : "inherit",
            fontWeight:
              row.kayitbitis.toDate() < new Date() || isAfterToday
                ? "bold"
                : "normal",
          }}
        >
          {row.kayitbitis.toDate() < new Date()
            ? "KAYITLAR BİTTİ"
            : isAfterToday
            ? row.kontenjan <= 5
              ? row.kontenjan == 0
                ? "KONTENJAN BİTTİ"
                : `SON ${row.kontenjan} KONTENJAN`
              : "KAYITLAR BAŞLADI"
            : row.kayitbaslama.toDate().toLocaleDateString("tr-TR", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
        </TableCell>
        <TableCell align="right">
          <Button
            aria-label="expand row"
            size="small"
            style={{ color: "#ff0000" }} // Set text color to white for contrast
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </Button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            backgroundColor: "#fcfafa",
          }}
          colSpan={7}
        >
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
            //addEndListener={
            //  open ? console.log("acildi") : console.log("kapandi")
            //}
          >
            <Box sx={{ margin: 1 }}>
              <Typography
                variant="h6"
                gutterBottom
                component="div"
                style={{ color: "#ff0000" }} // Set text color to white for contrast
              >
                Detaylar
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Kayıt Bitiş</TableCell>
                    {row.kontenjan <= 5 ? (
                      <TableCell>Kalan Kontenjan</TableCell>
                    ) : (
                      <TableCell></TableCell>
                    )}
                    <TableCell>Sınav Konumu</TableCell>
                    <TableCell align="right">Ücret(EUR)</TableCell>
                    <TableCell align="right">Ücret(TL)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={"detaylar" + row.id}>
                    <TableCell component="th" scope="row">
                      {row.kayitbitis.toDate().toLocaleDateString("tr-TR", {
                        //weekday: "long",
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}
                    </TableCell>
                    {row.kontenjan <= 5 ? (
                      <TableCell
                        style={{
                          color:
                            row.kayitbitis.toDate() < new Date()
                              ? "red"
                              : row.kontenjan < 15
                              ? "red"
                              : "inherit",
                          fontWeight:
                            row.kayitbitis.toDate() < new Date() ||
                            row.kontenjan < 15
                              ? "bold"
                              : "normal",
                        }}
                      >
                        {row.kayitbitis.toDate() < new Date() ||
                        row.kontenjan == 0
                          ? "0"
                          : `Son ${row.kontenjan} kontenjan`}
                      </TableCell>
                    ) : (
                      <TableCell></TableCell>
                    )}
                    <TableCell>{row.sinavyeri}</TableCell>

                    <TableCell align="right">
                      {formatNumber(calculateFiyat()) + "€"}
                    </TableCell>
                    <TableCell align="right">
                      {formatNumber(calculateFiyat(), exchangeRate) + "₺"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isYaziliChecked}
                            onChange={handleChange}
                            name="yazili"
                            color="primary"
                            disabled={
                              row.modul !== "Yazılı/Sözlü" ||
                              !isAfterToday ||
                              row.kayitbitis.toDate() < new Date() ||
                              (row.modul === "Yazılı/Sözlü" && !row.sozluucret)
                            }
                          />
                        }
                        label="Yazılı"
                      />
                    </TableCell>
                    <TableCell>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isSozluChecked}
                            onChange={handleChange}
                            name="sozlu"
                            color="primary"
                            disabled={
                              row.modul !== "Yazılı/Sözlü" ||
                              !isAfterToday ||
                              row.kayitbitis.toDate() < new Date() ||
                              (row.modul === "Yazılı/Sözlü" && !row.sozluucret)
                            }
                          />
                        }
                        label="Sözlü"
                      />
                    </TableCell>

                    <TableCell align="center"></TableCell>

                    <TableCell />
                    <TableCell align="right">
                      <Button
                        id="ToCheckout"
                        variant="contained"
                        style={{
                          backgroundColor:
                            row.kayitbaslama.toDate() > new Date() ||
                            row.kontenjan <= 0 ||
                            row.kayitbitis.toDate() < new Date()
                              ? "#bdbdbd"
                              : "#ff0000",
                          color: "#FFF",
                        }}
                        onClick={handleToCheckout}
                        disabled={
                          row.kayitbaslama.toDate() > new Date() ||
                          row.kontenjan <= 0 ||
                          row.kayitbitis.toDate() < new Date()
                        }
                      >
                        Ödemeye Geç
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

//`https://us-central1-sinav-app-alm.cloudfunctions.net/api/api/exchange-rate?apiKey=${TCMB_API_KEY}`

export default function ExamTable() {
  const [exchangeRate, setExchangeRate] = React.useState(null);

  React.useEffect(() => {
    const fetchExchangeRate = async () => {
      try {
        // Adjust the URL as necessary. If using credentials, make sure they're securely handled
        const response = await fetch(
          `https://us-central1-sinav-app-alm.cloudfunctions.net/api/exchange-rate`,
          {
            method: "GET", // Specify the method, since you're likely making a POST request for SOAP
            headers: {
              // You might need to adjust headers based on server expectations
              "Content-Type": "application/json", // Or 'text/plain' if the server responds with plain text
            },
            // For SOAP, you wouldn't typically send a body with a fetch request from the client like this,
            // but just showing how you could if needed
            //body: JSON.stringify({ username: "yourUsername", password: "yourPassword" }),
          }
        );

        // Assuming the server is sending back plain text or JSON
        const data = await response.json(); // or response.json() if your server sends JSON
        setExchangeRate(data.kur); // Adjust based on the actual response structure
        console.log(data.kur);
      } catch (error) {
        console.error("Error fetching exchange rate:", error);
      }
    };

    fetchExchangeRate();
  }, []); // Removed TCMB_API_KEY from dependencies if not used directly

  return (
    <TableContainer
      component={Paper}
      sx={{ maxHeight: "100%", overflow: "auto" }}
    >
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell color="primary">Tarih</TableCell>
            <TableCell color="primary">Seviye</TableCell>
            <TableCell color="primary">Sınav Türü</TableCell>
            <TableCell color="primary">Modül</TableCell>
            <TableCell color="primary">Şehir</TableCell>
            <TableCell align="right">Kayıt Başlama</TableCell>
            <TableCell align="right" />
          </TableRow>
        </TableHead>
        <TableBody>
          {sinavRows.map((row) => (
            <Row key={row.id} row={row} exchangeRate={exchangeRate} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
