import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Typography, Box, Button } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { green, red } from "@mui/material/colors";
import { PaymentTwoTone } from "@mui/icons-material";

const Sonuc = () => {
  const location = useLocation();

  const getQueryParams = (query) => {
    return new URLSearchParams(query);
  };

  const queryParams = new URLSearchParams(location.search);
  const registrationId = queryParams.get("registrationId");
  const paymentStatus = queryParams.get("paymentStatus");
  const paymentMessage = queryParams.get("paymentMessage");

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh", // Full viewport height
        textAlign: "center",
      }}
    >
      {paymentStatus === "SUCCESS" ? (
        <CheckCircleIcon sx={{ color: green[500], fontSize: "80px" }} />
      ) : (
        <CancelIcon sx={{ color: red[500], fontSize: "80px" }} />
      )}
      <Typography
        variant="h2"
        component="h3"
        gutterBottom
        sx={{ fontSize: "4rem", fontWeight: "bold" }}
      >
        {paymentStatus === "SUCCESS"
          ? "Ödemeniz Başarılı"
          : "Ödemeniz Başarısız"}
      </Typography>
      <Typography variant="h5" sx={{ mb: 3 }}>
        {paymentStatus === "SUCCESS"
          ? "Ödemenizi başarı ile aldık. Kısa zamanda faturanız ve sınav kayıt işlemine dair bir mail gönderilecektir. Bizi tercih ettiğiniz için teşekkür ederiz."
          : `Ödemeniz sırasında bir hata oluştu. Sorunun kaynağı: ${paymentMessage}. Lütfen tekrar deneyiniz.`}
      </Typography>
      <Button
        variant="contained"
        component={Link}
        to="/"
        sx={{ textTransform: "none" }}
      >
        Ana Sayfaya Dön
      </Button>
    </Box>
  );
};

export default Sonuc;
